import { ReactElement } from "react"

export enum PROVIDERS {
	LOST = "LOST",
	MONTAG = "montag",
	DIENSTAG = "dienstag",
	MITTWOCH = "mittwoch",
	DONNERSTAG = "donnerstag",
	FREITAG = "freitag",
	SAMSTAG = "samstag",
	SONNTAG = "sonntag",
}

export interface WeekPlanProps {
	getDay: Number
	day: string
	provider: PROVIDERS
	cardHeadline: { line1?: string; line2?: string; line3?: string }
	shortTextHeadline?: String
	shortText: ReactElement
	text: ReactElement
}

export const WEEKPLAN: WeekPlanProps[] = [
	{
		getDay: 1,
		day: "Montag",
		provider: PROVIDERS.MONTAG,
		cardHeadline: {
			line1: "Heute da: ",
			line2: "Ein süßes & ",
			line3: "lustiges Paar",
		},

		shortTextHeadline: "GEWINNE AN MONTAGEN",
		shortText: (
			<>
				An allen Montagen im Aktionszeitraum verlosen wir insgesamt:
				<br />
				<br />
				<div>
					<strong>77x Ravensburger Spiel „nimm2 Lachgummi memory"</strong> sowie nimm2 Lachgummi Frucht 250g, nimm2 Lachgummi Happies 325g und nimm2 Lachgummi Heroes 225g.
				</div>
			</>
		),
		text: (
			<p className="font-Museo">
				Gewinne eins von insgesamt 77 Ravensburger nimm2 Lachgummi memory Spielen für euren gemeinsamen Spielenachmittag! Damit der Spielspaß perfekt wird, gibt es dazu jeweils
				einen Beutel nimm2 Lachgummi Frucht 250g, nimm2 Lachgummi Happies 325g sowie nimm2 Lachgummi Heroes 225g.
			</p>
		),
	},
	{
		getDay: 2,
		day: "Dienstag",
		provider: PROVIDERS.DIENSTAG,
		cardHeadline: {
			line1: "Setzt dich auf die",
			line2: "nimm2 Sonnenseite",
		},
		shortTextHeadline: "GEWINNE AN DIENSTAGEN",
		shortText: (
			<>
				An allen Dienstagen im Aktionszeitraum verlosen wir insgesamt:
				<br />
				<br />
				<div>
					<strong>44x nimm2 Lachgummi Liegestuhl</strong> sowie nimm2 Lachgummi Frucht 250g und nimm2 Lachgummi Milchgeister 225g.
				</div>
			</>
		),
		text: (
			<p className="font-Museo">
				Dein perfekter Platz in der Sonne! Gewinne einen von 44 nimm2 Lachgummi Liegestühlen sowie jeweils einen Beutel nimm2 Lachgummi Frucht 250g und nimm2 Lachgummi Milchgeister
				225g.
			</p>
		),
	},
	{
		getDay: 3,
		day: "Mittwoch",
		provider: PROVIDERS.MITTWOCH,
		cardHeadline: {
			line1: "Für einen süßen ",
			line2: "Schlagabtausch",
		},
		shortTextHeadline: "GEWINNE AN MITTWOCHEN",
		shortText: (
			<>
				An allen Mittwochen im Aktionszeitraum verlosen wir insgesamt:
				<br />
				<br />
				<div>
					<strong>77x nimm2 Sommer Hit Standtasche und nimm2 soft Splash Beachball Set </strong> sowie nimm2 Sommer Hit 300g und nimm2 soft Splash 240g.
				</div>
			</>
		),
		text: (
			<p className="font-Museo">
				Lust auf einen süßen Schlagabtausch? Gewinne eins von 77 Sommerpaketen bestehend aus einer nimm2 Sommer Hit Strandtasche sowie einem nimm2 soft Splash Beachball Set! Für
				den perfekten Tag im Freien gibt es dazu passend jeweils einen Beutel nimm2 Sommer Hit 300g sowie nimm2 soft Splash 240g.
			</p>
		),
	},
	{
		getDay: 4,
		day: "Donnerstag",
		provider: PROVIDERS.DONNERSTAG,
		cardHeadline: {
			line1: "Ein riesen Gewinn",
			line2: "mit Vielfalt drin",
		},
		shortTextHeadline: "GEWINNE AN DONNERSTAGEN",
		shortText: (
			<>
				An allen Donnerstagen im Aktionszeitraum verlosen wir insgesamt:
				<br />
				<br />
				<div>
					<strong>110x nimm2 Produktpaket.</strong>
				</div>
			</>
		),
		text: (
			<p className="font-Museo">
				Ein riesen Gewinn mit Vielfalt drin! Gewinne eins von 110 Produktpaketen von nimm2 bestehend aus 10 Produkten (jeweils 1 Beutel nimm2 Lachgummi Sofies Frucht 225g, nimm2
				Lachgummi Frucht 250g, nimm2 Lachgummi Fluffies 200g, nimm2 Lachgummi Cola Flaschies 200g, nimm2 Lachgummi Milchgeister 225g, nimm2 Lachgummi Mümmelbande 200g, nimm2
				Lachgummi Apfellinge 225g, nimm2 Bonbon Orange Zitrone 240g, nimm2 soft 195g sowie nimm2 Lolly 120g).
			</p>
		),
	},
	{
		getDay: 5,
		day: "Freitag",
		provider: PROVIDERS.FREITAG,
		cardHeadline: {
			line1: "Unterwegs mit Spaß ",
			line2: "im Gepäck",
		},
		shortTextHeadline: "GEWINNE AN FREITAGEN",
		shortText: (
			<>
				An allen Freitagen im Aktionszeitraum verlosen wir insgesamt:
				<br />
				<br />
				<div>
					<strong>44x Koffer </strong> der Marke American Tourister sowie nimm2 Bonbon Orange Zitrone 240g und nimm2 soft Frucht 195g.
				</div>
			</>
		),
		text: (
			<p className="font-Museo">
				Unterwegs mit Spaß im Gepäck! Gewinne einen von insgesamt 44 Koffern der Marke American Tourister, Modell Soundbox 55cm in der Farbe Spicy Peach! Als perfekten Snack für
				unterwegs gibt es jeweils einen Beutel nimm2 Bonbon Orange Zitrone 240g sowie nimm2 soft Frucht 195g dazu.
			</p>
		),
	},
	{
		getDay: 6,
		day: "Samstag",
		provider: PROVIDERS.SAMSTAG,
		cardHeadline: {
			line1: "nimm2",
			line2: "Badespaß",
		},

		shortTextHeadline: "GEWINNE AN SAMSTAGEN",
		shortText: (
			<>
				An allen Samstagen im Aktionszeitraum verlosen wir insgesamt:
				<br />
				<br />
				<div>
					<strong>44x nimm2 Lachgummi Cola Flaschies Luftmatratze</strong> und nimm2 Lachgummi Fluffies Badehandtuch sowie nimm2 Lachgummi Cola Flaschies 200g und nimm2 Lachgummi
					Fluffies 200g.
				</div>
			</>
		),
		text: (
			<p className="font-Museo">
				Dein perfekter Badetag! Gewinne eine von 44 nimm2 Lachgummi Cola Flaschies Luftmatratzen und ein nimm2 Lachgummi Fluffies Badehandtuch sowie jeweils einen Beutel nimm2
				Lachgummi Cola Flaschies 200g und nimm2 Lachgummi Fluffies 200g.
			</p>
		),
	},
	{
		getDay: 0,
		day: "Sonntag",
		provider: PROVIDERS.SONNTAG,
		cardHeadline: {
			line1: "Umwerfend gut:",
			line2: "nimm2 Wikinger",
			line3: "Wurfspiel",
		},

		shortTextHeadline: "GEWINNE AN SONNTAGEN",
		shortText: (
			<>
				An allen Sonntagen im Aktionszeitraum verlosen wir insgesamt:
				<br />
				<br />
				<div>
					<strong>77x nimm2 Wikinger Wurfspiel </strong> sowie nimm2 Lachgummi Gartenzwerge 200g und nimm2 Lachgummi Mümmelbande 200g.
				</div>
			</>
		),
		text: (
			<p className="font-Museo">
				Umwerfend gut: Gewinne eins von insgesamt 77 nimm2 Wikinger Wurfspielen! Als Snack für die Spielpause gibt es dazu jeweils einen Beutel nimm2 Lachgummi Gartenzwerge 200g
				sowie nimm2 Lachgummi Mümmelbande 200g.
			</p>
		),
	},
]

export const PRICE_TYPE: any = {
	"0": {
		provider: PROVIDERS.LOST,
		key: "PRIZE_BLANK",
	},
	"1": {
		provider: PROVIDERS.MONTAG,
		key: "77_Ravensburger_nimm2_Lachgummi_memory",
	},
	"2": {
		provider: PROVIDERS.DIENSTAG,
		key: "44_nimm2_Lachgummi_Liegestuhl",
	},
	"3": {
		provider: PROVIDERS.MITTWOCH,
		key: "77_nimm2_Strandtasche_und_Beachball Set",
	},
	"4": {
		provider: PROVIDERS.DONNERSTAG,
		key: "110_nimm2_product_package",
	},
	"5": {
		provider: PROVIDERS.FREITAG,
		key: "44_Koffer_American_Tourister",
	},
	"6": {
		provider: PROVIDERS.SAMSTAG,
		key: "44_nimm2_Luftmatratze_und_Badehandtuch",
	},
	"7": {
		provider: PROVIDERS.SONNTAG,
		key: "77_nimm2_Wikinger_Wurfspiel",
	},
}
