import { BrowserRouter, Route, Routes } from "react-router-dom"
import Win from "./nav/Win"
import Contact from "./nav/Contact"
import Error from "./nav/Error"
import FAQ from "./nav/FAQ"
import Imprint from "./nav/Imprint"
import Memory from "./nav/Memory"
import Privacy from "./nav/Privacy"
import Promoover from "./nav/Promoover"
import Terms from "./nav/Terms"
import Store from "./store/store"
import Lose from "./nav/Lose"
import ConfirmContact from "./nav/ConfirmContact"
import { Header } from "./components/Header"
import { useEffect } from "react"
import { updateIframeHeight } from "./helper"
import Home from "./nav/Home"
import Promosoon from "./nav/Promosoon"

const App = () => {
	//  Mon Sep 04 2023 00:00:00 UTC+0200 (Mitteleuropäische Sommerzeit)   https://time.is/de/Unix_time_converter
	const isPromoOver = true // parseInt(Math.floor(Date.now() / 1000).toString()) > 1725228000
	useEffect(() => {
		updateIframeHeight()
		window.addEventListener("load", () => updateIframeHeight("load"))
		window.addEventListener("resize", () => updateIframeHeight("resize"))
		window.addEventListener("focus", () => updateIframeHeight("focus"))
	}, [])

	return (
		<BrowserRouter basename={`${process.env.PUBLIC_URL}`}>
			<div>
				<Store>
					<Header />
					<main>
						<Routes>
							{isPromoOver ? (
								<>
									<Route path="/" element={<Promoover />} />
									<Route path="/faq" element={<FAQ />} />
									<Route path="/datenschutz" element={<Privacy />} />
									<Route path="/teilnahmebedingungen" element={<Terms />} />
									<Route path="/impressum" element={<Imprint />} />
									<Route path="/kontakt" element={<Contact />} />
									<Route path="/:err" element={<Error />} />
								</>
							) : (
								<>
									<Route path="/" element={<Home />} />
									<Route path="/memory" element={<Memory />} />
									<Route path="/bestaetigung" element={<Win />} />
									<Route path="/kontakt/bestaetigung" element={<ConfirmContact />} />
									<Route path="/keinmatch" element={<Lose />} />
									<Route path="/promosoon" element={<Promosoon />} />
									<Route path="/promoover" element={<Promoover />} />
									<Route path="/faq" element={<FAQ />} />
									<Route path="/datenschutz" element={<Privacy />} />
									<Route path="/teilnahmebedingungen" element={<Terms />} />
									<Route path="/impressum" element={<Imprint />} />
									<Route path="/kontakt" element={<Contact />} />
									<Route path="/:err" element={<Error />} />
								</>
							)}{" "}
						</Routes>
					</main>
				</Store>

				<div className="bg-white pb-20">
					<a href="https://www.nimm2.de/de/ideen-inspirationen" target="_parent" rel="noopener noreferrer" className="block max-w-6xl mx-auto">
						<img src={process.env.PUBLIC_URL + "/images/inspirationen/nimm2-DE-der-sommer-lacht-ideen-inspirationen-desktop.jpg"} className="hidden lg:block w-full " alt="" />
						<img
							src={process.env.PUBLIC_URL + "/images/inspirationen/nimm2-DE-der-sommer-lacht-ideen-inspirationen-tablet.jpg"}
							className="hidden sm:block lg:hidden w-full "
							alt=""
						/>
						<img src={process.env.PUBLIC_URL + "/images/inspirationen/nimm2-DE-der-sommer-lacht-ideen-inspirationen-mobile.jpg"} className="sm:hidden w-full " alt="" />
					</a>
				</div>
			</div>
		</BrowserRouter>
	)
}

export default App
