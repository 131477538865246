import { Link } from "react-router-dom"

const headerLiClasses = "inline-block mx-[1%] sm:mx-[3%] sm:order-2"
const headerAClasses = "font-Museo text-white uppercase tracking-[1px] hover:text-[#ff5b0c] font-bold hover:transition-colors"

//const isPromoOver = parseInt(Math.floor(Date.now() / 1000).toString()) < 1718604000
const isPromoOver = false
export const Header = () => (
	<header className="headerGradient text-lg p-2">
		<nav>
			<ul className="text-center flex flex-wrap justify-center text-[15px]">
				{!isPromoOver && (
					<>
						<li className={headerLiClasses + " order-3"}>
							<a href="/" rel="nofollow" className={headerAClasses}>
								Mitspielen
							</a>
						</li>
						<li className={headerLiClasses + " order-3"}>
							<a href="/memory#gewinne" rel="nofollow" className={headerAClasses}>
								Gewinne
							</a>
						</li>
					</>
				)}
				<li className={headerLiClasses}>
					<Link to="/teilnahmebedingungen" className={headerAClasses}>
						Teilnahmebedingungen
					</Link>
				</li>
				<li className={headerLiClasses}>
					<Link to="/kontakt" className={headerAClasses}>
						Kontakt
					</Link>
				</li>
				<li className={headerLiClasses}>
					<Link to="/faq" className={headerAClasses}>
						FAQ
					</Link>
				</li>
			</ul>
		</nav>
	</header>
)
