import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import AccordionItem from "../components/elements/AccordionItem"
import Fruchtgummi from "../components/Fruchtgummi"
import { updateIframeHeight, updateIframeScrollTo } from "../helper"

const FAQ: React.FC = () => {
	useEffect(() => {
		updateIframeScrollTo(0)
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})

		updateIframeHeight()
		// eslint-disable-next-line
	}, [])

	return (
		<div className="contentGradient">
			<div className="container max-w-[800px] mx-auto px-4 ">
				<div>
					<h2 className="h2 py-6 sm:py-12">FAQ</h2>
				</div>
				<h3 className="h3 my-8 text-center ">1. Allgemeine Informationen (zur Aktion)</h3>
				<AccordionItem headline="Worum geht es bei der aktuellen Aktion von “Der Sommer lacht mit nimm2”?" num="1.">
					<p className="text-black text-left">
						Bei der Aktion “Der Sommer lacht mit nimm2” handelt es sich um ein Online-Gewinnspiel. Im Rahmen unseres Gewinnspiels muss der Teilnehmer zwei der zwölf abgebildeten
						Aktionsfelder anklicken. Die Gewinne werden nach dem Zufallsprinzip vergeben. Wenn der Teilnehmer gewonnen hat, erscheinen hinter beiden angeklickten Aktionsfeldern
						dieselbe Abbildung des Gewinns. Der Teilnehmer erfährt sofort, ob und was er gewonnen hat. Erscheinen hinter einem oder beiden Aktionsfeldern keine Abbildung des
						Gewinns oder nicht dieselbe Abbildung, hat der Teilnehmer nicht gewonnen.
					</p>
				</AccordionItem>
				<AccordionItem headline="Wer darf an der Aktion teilnehmen?" num="2.">
					<p className="text-black text-left">
						Teilnahmeberechtigt sind natürliche Personen ab 18 Jahren. Die Teilnahme ist nur innerhalb Deutschlands möglich. Eine automatisierte Teilnahme ist nicht zulässig.
					</p>
				</AccordionItem>
				<h3 className="h3 my-8 text-center ">2. Durchführung und Abwicklung</h3>
				<AccordionItem headline="Wie kann ich an dem Gewinnspiel teilnehmen?" num="3.">
					<div className="">
						<p className="text-black py-4">
							<ul>
								<li>
									1. Aktionswebseite unter{" "}
									<a href="https://www.dersommerlacht.de" target="_blank" rel="noopener noreferrer">
										www.dersommerlacht.de
									</a>{" "}
									aufrufen.
								</li>
								<li>2. Gewinne für den jeweiligen Wochentag entdecken.</li>
								<li>3. Captcha ausfüllen und zwei Aktionsfelder auf dem Spielfeld aufdecken.</li>
								<li>
									4. Nach dem Aufdecken der beiden Aktionsfelder erfährst du direkt, ob du gewonnen hast. Zeigen die aufgedeckten Aktionsfelder dasselbe Bild hast du gewonnen. Im
									Anschluss bitte deine persönlichen Daten angeben, damit wir dir deinen Gewinn zusenden können.
								</li>
							</ul>
						</p>
					</div>
				</AccordionItem>
				<AccordionItem headline="Ist eine mehrmalige Teilnahme am Gewinnspiel möglich?" num="4.">
					<p className="text-black text-left">Eine Mehrfachteilnahme ist möglich, jedoch darf jeder Teilnehmer nur einmal gewinnen. </p>
				</AccordionItem>
				<AccordionItem headline="In welchem Zeitraum kann an dem Gewinnspiel teilgenommen werden?" num="5.">
					<p className="text-black text-left">Eine Teilnahme an dem Gewinnspiel ist im Aktionszeitraum vom 17.06.2024 bis 01.09.2024 möglich. </p>
				</AccordionItem>
				<AccordionItem headline="Was kann man gewinnen?" num="6.">
					<div className="text-left">
						<p className="text-black mb-4">
							Die Gewinne sind den Wochentagen zugeordnet und werden jeweils an dem entsprechenden Wochentag verlost. An jedem Tag gibt es mindestens einen Gewinnmoment.
						</p>
						<p className="text-black mb-4">An allen Montagen im Aktionszeitraum verlosen wir insgesamt:</p>

						<p className="text-black mb-4">
							<span className="underline">
								Gewinne an Montagen <br />
							</span>
							An allen Montagen im Aktionszeitraum verlosen wir insgesamt: <br />
							77x Ravensburger Spiel: „nimm2 Lachgummi memory“ (exklusive Sonderanfertigung für Storck) sowie nimm2 Lachgummi Frucht 250g, nimm2 Lachgummi Happies 325g und nimm2
							Lachgummi Heroes 225g
						</p>

						<p className="text-black mb-4">
							<span className="underline">
								Gewinne an Dienstagen
								<br />
							</span>
							An allen Dienstagen im Aktionszeitraum verlosen wir insgesamt:
							<br />
							44x nimm2 Lachgummi Liegestuhl sowie nimm2 Lachgummi Frucht 250g und nimm2 Lachgummi Milchgeister 225g
						</p>

						<p className="text-black mb-4">
							<span className="underline">
								Gewinne an Mittwochen
								<br />
							</span>
							An allen Mittwochen im Aktionszeitraum verlosen wir insgesamt:
							<br />
							77x nimm2 Sommer Hit Strandtasche und nimm2 soft Splash Beachball Set sowie nimm2 Sommer Hit 300g und nimm2 soft Splash 240g
						</p>

						<p className="text-black mb-4">
							<span className="underline">
								Gewinne an Donnerstagen
								<br />
							</span>
							An allen Donnerstagen im Aktionszeitraum verlosen wir insgesamt:
							<br />
							110x Produktpaket von nimm2, bestehend aus nimm2 Lachgummi Sofies Frucht 225g, nimm2 Lachgummi Frucht 250g, nimm2 Lachgummi Fluffies 200g, nimm2 Lachgummi Cola
							Flaschies 200g, nimm2 Lachgummi Milchgeister 225g, nimm2 Lachgummi Mümmelbande 200g, nimm2 Lachgummi Apfellinge 225g, nimm2 Bonbon Orange Zitrone 240g, nimm2 soft
							195g, nimm2 Lolly 120g{" "}
						</p>

						<p className="text-black mb-4">
							<span className="underline">
								Gewinne an Freitagen:
								<br />
							</span>
							An allen Freitagen im Aktionszeitraum verlosen wir insgesamt: <br />
							44x Koffer der Marke American Tourister, Modell Soundbox 55cm in der Farbe Spicy Peach sowie nimm2 Bonbon Orange Zitrone 240g und nimm2 soft Frucht 195g
						</p>

						<p className="text-black mb-4">
							<span className="underline">
								Gewinne an Samstagen
								<br />
							</span>
							An allen Samstagen im Aktionszeitraum verlosen wir insgesamt:
							<br />
							44x nimm2 Lachgummi Cola Flaschies Luftmatratze und nimm2 Lachgummi Fluffies Badehandtuch sowie nimm2 Lachgummi Cola Flaschies 200g und nimm2 Lachgummi Fluffies 200g
						</p>

						<p className="text-black mb-4">
							<span className="underline">
								Gewinne an Sonntagen
								<br />
							</span>
							An allen Sonntagen im Aktionszeitraum verlosen wir insgesamt: <br />
							77x nimm2 Wikinger Wurfspiel sowie nimm2 Lachgummi Gartenzwerge 200g und nimm2 Lachgummi Mümmelbande 200g{" "}
						</p>

						<p className="text-black mb-4">
							Eine Teilnahme an dem Gewinnspiel ist im Aktionszeitraum vom 17.06.2024 bis 01.09.2024 möglich. Weitere Informationen findest du in den{" "}
							<Link
								className="underline"
								to="/teilnahmebedingungen"
								onClick={() => {
									try {
										// eslint-disable-next-line
										let dataLayer = window["dataLayer"] || []
										dataLayer.push({ event: "faq", link: "teilnahmebedingungen" })
									} catch (e) {}
								}}
							>
								Teilnahmebedingungen
							</Link>
							.
						</p>
					</div>
				</AccordionItem>
				<AccordionItem headline="Wie erfahre ich, ob ich gewonnen habe?" num="7.">
					<div className="text-left">
						<p className="text-black">
							Nachdem der Teilnehmer das Captcha ausgefüllt und zwei der zwölf abgebildeten Aktionsfelder angeklickt hat, wird den Teilnehmenden direkt im Anschluss angezeigt, ob
							er gewonnen oder verloren hat.
						</p>
						<p className="text-black">
							Wenn der Teilnehmer gewonnen hat, erscheinen hinter beiden angeklickten Aktionsfeldern dieselbe Abbildung des Sachgewinns. Im Gewinnfall erscheint die Meldung „YEAH!
							Gewonnen!“ sowie ein Bild des Gewinns. Der Gewinner erhält direkt eine Information, welchen Sofortgewinn er gewonnen hat und wird gebeten seine persönlichen Daten
							einzugeben.
						</p>
						<p className="text-black">
							Erscheinen hinter einem oder beiden Aktionsfeldern keine Abbildung des Gewinns oder nicht dieselbe Abbildung, hat der Teilnehmer nicht gewonnen und die Meldung
							„Leider kein Match gefunden!“ erscheint.
						</p>
					</div>
				</AccordionItem>
				<AccordionItem headline="Ich habe versehentlich einen Fehler bei der Dateneingabe gemacht, was kann ich tun?" num="8.">
					<div className="text-left">
						<p className="text-black">
							Bitte wende dich umgehend an den nimm2 Aktions-Kundenservice telefonisch unter 030 / 47 06 00 60 (erreichbar von Montag bis Freitag von 08:30 - 17:30 Uhr) oder per
							E-Mail an{" "}
							<a
								className="underline"
								href="mailto:info@nimm2-gewinnspiel.de"
								onClick={() => {
									try {
										// eslint-disable-next-line
										let dataLayer = window["dataLayer"] || []
										dataLayer.push({ event: "faq", link: "email" })
									} catch (e) {}
								}}
							>
								info@nimm2-gewinnspiel.de
							</a>
							.{" "}
						</p>
					</div>
				</AccordionItem>
				<h3 className="h3 my-8 text-center ">3. Nach der Teilnahme</h3>
				<AccordionItem headline="Wie erhalte ich meinen Gewinn?" num="9.">
					<div className="text-left">
						<p className="text-black">
							Für die Übersendung der Sachgewinne werden im Rahmen der Promotion persönliche Daten (Vorname, Name, Adresse sowie optional E-Mail-Adresse) abgefragt. Für die
							vollständige und korrekte Angabe ist der Teilnehmer verantwortlich. Der Versand der Sachgewinne erfolgt inner-halb von vier Wochen nach Teilnahme per DHL,
							ausschließlich innerhalb Deutschlands an die Storck über die entsprechende Eingabemaske mitgeteilte Adresse. Sollte der Teilnehmer keine Kontaktdaten für den Versand
							des Gewinns angegeben haben oder unter der angegebenen Adresse eine Zustellung nicht möglich sein, erlischt der Anspruch auf den Gewinn. Eine Barauszahlung der
							Gewinne ist nicht möglich.
						</p>
					</div>
				</AccordionItem>
				<AccordionItem headline="Wie lange dauert es, bis ich meinen Gewinn erhalte?" num="10.">
					<div className="text-left">
						<p className="text-black">
							Sofern du alle Teilnahmevoraussetzungen erfüllt hast und deine erforderlichen persönlichen Daten vollständig und korrekt übermittelt hast, bekommst du deinen Gewinn
							innerhalb von 4 Wochen per Post an die von dir angegebene Adresse zugeschickt.
						</p>
					</div>
				</AccordionItem>
				<h3 className="h3 my-8 text-center ">4. Sonstiges</h3>

				<AccordionItem headline="Was passiert mit meinen Daten?" num="11.">
					<div className="text-left">
						<p className="text-black">
							Storck nutzt deine Daten im Rahmen des Gewinnspiels „Der Sommer lacht mit nimm2“ ausschließlich zum Zwecke der Durchführung und Abwicklung der Promotion. Eine
							weitergehende Nutzung durch Storck oder Dritte findet nicht statt. Im Rahmen der Aktion arbeitet Storck mit seiner Tochtergesellschaft Storck Deutschland KG sowie dem
							sorgfältig ausgewählten Dienstleister direct services Gütersloh GmbH zusammen. Die für Storck tätigen Auftragsverarbeiter sind ordnungsgemäß auf die Einhaltung des
							Datenschutzes verpflichtet. Weitere Informationen zum Datenschutz findest du unter:{" "}
							<Link
								className="underline"
								to="/datenschutz"
								onClick={() => {
									try {
										// eslint-disable-next-line
										let dataLayer = window["dataLayer"] || []
										dataLayer.push({ event: "faq", link: "datenschutz" })
									} catch (e) {}
								}}
							>
								hier
							</Link>
							.
						</p>
					</div>
				</AccordionItem>
				<div className="text-white py-8 text-center">
					<p className="mb-6 text-white">Du hast keine Antwort auf deine Frage erhalten?</p>
					<p className="text-white">
						Das Service-Team ist von Montag bis Freitag von 08:30 - 17:30 Uhr telefonisch erreichbar unter 030 / 47 06 00 60 oder per E-Mail unter{" "}
						<a
							className="underline"
							href="mailto:info@nimm2-gewinnspiel.de"
							onClick={() => {
								try {
									// eslint-disable-next-line
									let dataLayer = window["dataLayer"] || []
									dataLayer.push({ event: "faq", link: "email" })
								} catch (e) {}
							}}
						>
							info@nimm2-gewinnspiel.de
						</a>
						.
					</p>
				</div>
			</div>
			<Fruchtgummi />
		</div>
	)
}
export default FAQ
