import React, { useEffect } from "react"
import { updateIframeHeight, updateIframeScrollTo } from "../helper"

const Error: React.FC = () => {
	useEffect(() => {
		updateIframeScrollTo(0)
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})

		updateIframeHeight()
		// eslint-disable-next-line
	}, [])

	return (
		<div className="contentGradient">
			<div className="container max-w-[800px] mx-auto px-4 pt-20">
				<div className="text-center relative z-0 -mt-[1px] max-w-[750px] ml-auto mr-auto">
					<h2 className="h3 pb-7  px-4">Hier ist etwas schief gelaufen...</h2>
				</div>
			</div>
			<div className="text-center pt-5 py-10">
				<a href="/" className="inline-block btn  mt-8">
					Zurück zur Aktion
				</a>
			</div>
		</div>
	)
}
export default Error
