import React, { useEffect } from "react"
import AccordionItem from "../components/elements/AccordionItem"
import { updateIframeHeight, updateIframeScrollTo } from "../helper"

const Privacy: React.FC = () => {
	let cookieToggleCheckbox: any = null
	let cookieToggleLabel: any = null
	let cookieToggle: any = null
	let trackingCookieName: any = "trackingAccepted"
	let pageLang: any = null
	let languages: any = {}

	useEffect(() => {
		window["stg"] = window["stg"] || {}
		window["stg"].optedOutCheckboxText = "Tracking ist aktiviert."
		window["stg"].optedInCheckboxText = "Tracking ist deaktiviert."
		window["stg"].isGlobal = true
		window["stg"].instanceHostname = "//logfiles.storck.com"

		const script = document.createElement("script")
		script.src = "//logfiles.storck.com/containers/privacy/script.v2.js"
		script.async = true
		document.body.appendChild(script)

		initTrackingCheckbox()
		// eslint-disable-next-line
	}, [])

	// storeUserOptOutPreferences = () => {
	//     window.parent.storeUserOptOutPreferences();
	// }

	function initTrackingCheckbox() {
		cookieToggleCheckbox = document.getElementById("js-cookie-toggle-checkbox")
		cookieToggleLabel = document.getElementById("js-cookie-toggle-label")
		cookieToggle = document.getElementById("js-cookie-toggle")
		pageLang = cookieToggle ? cookieToggle.dataset.lang : "en_COM"
		languages = {
			currentLanguage: pageLang,
			de_DE: {
				on: " Tracking ist aktiviert.",
				off: " Tracking ist deaktiviert.",
			},
			en_DE: {
				on: " Tracking ist aktiviert",
				off: " Tracking ist deaktiviert.",
			},
		}
		setInitialState()
		cookieToggleCheckbox.addEventListener("click", e => {
			updateCookie()
			updateText()
			updateCookieCheckbox()
		})
	}

	function getCookieValue() {
		var cookieName = "trackingAccepted=",
			decodedCookie = decodeURIComponent(document.cookie),
			ca = decodedCookie.split(";"),
			cookieValue = ""
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i]
			while (c.charAt(0) === " ") {
				c = c.substring(1)
			}
			if (c.indexOf(cookieName) === 0) {
				cookieValue = c.substring(cookieName.length, c.length)
			}
		}
		return cookieValue
	}

	function setInitialState() {
		updateText()
		updateCookieCheckbox()
	}

	function updateCookie() {
		var d = new Date()
		d.setTime(d.getTime() + 180 * 24 * 60 * 60 * 1000)
		var expires = d.toUTCString()
		document.cookie = trackingCookieName + "=" + !(getCookieValue() === "true") + "; expires=" + expires + "; path=/"
	}

	function setText(text) {
		cookieToggleLabel.innerHTML = text
	}

	function updateText() {
		var value = languages[languages.currentLanguage] || languages["en_COM"]
		var text = getCookieValue() === "true" ? value.on : value.off
		setText(text)
	}

	function updateCookieCheckbox() {
		cookieToggleCheckbox.checked = getCookieValue() === "true"
	}

	useEffect(() => {
		updateIframeScrollTo(0)
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
		updateIframeHeight()
		// eslint-disable-next-line
	}, [])

	return (
		<div>
			<>
				<div className="contentGradient">
					<div className="container max-w-[800px] mx-auto px-4  py-12">
						<h2 className="h2 mb-16 text-center">
							<div className="text-white  mb-16 font-Atma  font-bold">Datenschutz</div>
						</h2>

						<AccordionItem headline={"Allgemeines"} num="1.">
							<p className="mb-6 text-black">
								Die AUGUST STORCK KG (nachfolgend Storck genannt) nimmt den Schutz Ihrer persönlichen Daten sehr ernst und erhebt und nutzt Ihre Daten nur gemäß den Vorgaben der
								gesetzlichen Datenschutzbestimmungen.
							</p>
							<p className="mb-6 text-black"> Storck ist Verantwortlicher für die Verarbeitung Ihrer Daten. Unsere Kontaktdaten sind: </p>
							<p className="mb-6 text-black">
								Adresse: AUGUST STORCK KG, Waldstraße 27, 13403 Berlin, Deutschland
								<br /> E-Mail-Adresse:{" "}
								<a className="underline" href="mailto:info@de.storck.com">
									info@de.storck.com
								</a>
								<br /> Telefon: +49 30 41773-03
							</p>
							<p className="mb-6 text-black">
								Wir haben einen Datenschutzbeauftragten bestellt, der für die Einhaltung dieser Datenschutzerklärung und die Beantwortung diesbezüglicher Fragen verantwortlich ist.
								Sollten Sie Fragen zu dieser Datenschutzerklärung haben, insbesondere Anfragen zur Ausübung Ihrer Rechte, kontaktieren Sie bitte unseren Datenschutzbeauftragten
								unter{" "}
								<a
									className="underline"
									href="mailto:datenschutz@de.storck.com"
									onClick={() => {
										try {
											// eslint-disable-next-line
											let dataLayer = window["dataLayer"] || []
											dataLayer.push({ event: "privacy", link: "datenschutz" })
										} catch (e) {}
									}}
								>
									datenschutz@de.storck.com
								</a>
								.
							</p>
							<p className="text-black">
								Soweit sich auf dieser Website Links zu anderen Seiten im Internet befinden, gilt diese Unterrichtung nicht für die Datenerhebung und -nutzung auf den verlinkten
								Seiten, da Storck auf deren Gestaltung und Inhalte keinerlei Einfluss hat.
							</p>
						</AccordionItem>

						<AccordionItem headline="Art, Umfang und Zwecke der Datenver&shy;arbeitung" num="2.">
							<p className="font-bold text-black">2.1 Allgemeine Websitenutzung</p>
							<p className="mb-6 text-black">
								Wenn Sie unsere Website besuchen, werden temporär bestimmte Verbindungsdaten verarbeitet (insbesondere die IP-Adresse des anfragenden Rechners; die zuletzt besuchte
								URL; Zeit und Datum des Zugriffs; abgerufene Webseiten und Elemente wie Unterseiten oder Buttons; die übertragene Datenmenge; der Status, ob der Abruf erfolgreich
								war; die Beschreibung des Typs des verwendeten Webbrowsers und Gerätes). Wir verarbeiten diese Daten, soweit dies für Ihren Besuch auf der Website erforderlich ist.
								Rechtsgrundlage ist unser berechtigtes Interesse im Sinne von Artikel 6 Abs. 1 f EU-Datenschutzgrundverordnung (DSGVO) an der Bereitstellung unserer Website für die
								Zwecke der Außendarstellung und des Marketings. Außerdem speichern wir die Verbindungsdaten in den Logfiles unseres Webservers in unserem berechtigten Interesse an
								dem sicheren Betrieb des Webservers. Im Falle Ihrer Einwilligung verarbeiten wir die Verbindungsdaten in pseudonymisierter Form auch für die unter 2.2 genannten
								Zwecke.
							</p>
							<p className="mb-6 text-black">
								Bis auf die in dem vorstehenden Absatz genannten Daten ist die Nutzung unserer Website ohne Angabe personenbezogener Daten möglich (siehe auch Ziffer 7).
							</p>
							<p className="font-bold text-black">2.2 Nutzungsanalyse zu Marketingzwecken sowie zur Website-Optimierung (Piwik PRO)</p>
							<p className="mb-6 text-black">
								Auf den Websites von Storck werden mit der Software Piwik PRO Daten zu Marketing- und Optimierungszwecken verarbeitet, wenn Sie hierzu beim ersten Aufruf der
								jeweiligen Website Ihre Einwilligung erteilen. Zu den genannten Zwecken werden pseudonymisierte Nutzungsprofile mit den in Ziffer 2.1 genannten Daten sowie Daten
								über Ihre Aktivitäten auf unserer Website (insbesondere welche Unterseiten und Buttons Sie wann genutzt haben) erstellt. Diese Profile enthalten keine Daten, mit
								denen Sie persönlich identifiziert werden können (wie z. B. Ihren Namen) und werden auch nicht mit solchen Daten kombiniert. Rechtsgrundlage für diese Analyse ist
								Ihre Einwilligung (Art. 6 Abs. 1 a DSGVO).
							</p>
							<p className="mb-6 text-black">
								{" "}
								Die Verarbeitung erfolgt im Auftrag von Storck auf Servern, die von der Piwik PRO GmbH, Lina-Bommer-Weg 6, 51149 Köln, in Deutschland gehostet werden.{" "}
							</p>
							<p className="mb-6 text-black">
								Zur Nutzungsanalyse können Cookies (vgl. Ziffer 2.3) eingesetzt werden, um die Wiedererkennung Ihres Internetbrowsers zu ermöglichen. Wenn Sie unsere Website das
								erste Mal besuchen, bitten wir Sie um Ihre Einwilligung, Cookies für die hier genannten Zwecke zu verwenden und die hier beschriebene Nutzungsanalyse durchzuführen.
								Wenn Sie diese Einwilligung widerrufen möchten, können Sie hierzu die im nachfolgenden Absatz beschriebenen Mechanismen nutzen.
							</p>
							<p className="mb-6 text-black">
								Sie können die pseudonymisierte Nutzungsanalyse durch Piwik PRO jederzeit deaktivieren. Hierzu können Sie in Ihrem Browser die Option „Do-Not-Track“ aktivieren
								(mehr Informationen finden Sie in der Hilfe-Sektion Ihres Browsers). Alternativ können Sie per Klick die Checkbox aktivieren und ein Cookie setzen, das ein weiteres
								Tracking durch Piwik PRO unterbindet:
							</p>
							{/* Start Piwik PRO Tag Manager privacy opt-out code */}
							<div id="js-cookie-toggle" className="cookie-toggle__wrapper" data-lang="en_DE">
								<input id="js-cookie-toggle-checkbox" type="checkbox" />
								<label id="js-cookie-toggle-label" htmlFor="js-cookie-toggle-checkbox" style={{ paddingLeft: "5px", fontWeight: "normal" }}></label>
							</div>
							{/* End Piwik PRO Tag Manager privacy opt-out code */}
							<p className="mb-6 text-black"> Informationen dazu, wie Sie unsere Website ganz ohne Cookies nutzen können, finden Sie unter Ziffer 2.3. </p>{" "}
							<p className="font-bold text-black">2.3 Cookies</p>
							<p className="mb-6 text-black">
								Wenn Sie unsere Website besuchen, weisen wir Sie darauf hin, dass wir für eine bessere Funktionalität unserer Website und, sofern Sie hierzu Ihre Einwilligung
								erteilt haben, zur Durchführung von Analysen des Nutzerverhaltens zu Marketing- und Optimierungszwecken (Ziffer 2.2) Cookies nutzen.
							</p>
							<p className="mb-6 text-black">
								Cookies sind kleine Text-Dateien, die von einem Webserver an Ihren Browser gesendet und auf Ihrem Computer, Smartphone oder Tablet gespeichert werden. Dies
								ermöglicht es, Sie beim wiederholten Besuch der Website wiederzuerkennen. So können wir eine bessere Funktionalität der Seite gewährleisten. Das Setzen von
								funktionalen Cookies, die für eine sinnvolle Nutzung der Website erforderlich sind (sog. notwendige Cookies) liegt in unserem berechtigten Interesse an einer
								funktionsfähigen und gut bedienbaren Website. Sie können der Verarbeitung von Daten mittels dieser Cookies aber widersprechen, indem Sie Cookies in Ihren
								Browsereinstellungen deaktivieren oder die Cookies über den Browser aus Ihrem Speicher löschen. In diesem Fall kann eine vollständige Funktion unserer Website nicht
								gewährleistet werden (z. B. werden Sie ggf. bei jedem Aufruf der Seite erneut nach Ihrer Einwilligung in Cookies gefragt, da Ihre Entscheidung ohne das hierfür
								benötigte Cookie nicht gespeichert werden kann).
							</p>
							<p className="mb-6 text-black">
								Darüber hinaus können wir – wenn Sie hierin einwilligen – Cookies setzen, um Webanalysen durchführen (vgl. Ziffer 2.2). Sofern Sie Ihre Einwilligung hierzu gegeben
								haben, können Sie diese jederzeit widerrufen. Um Ihre Einwilligung in diese Nutzungsanalyse und die damit verbundenen Cookies zu widerrufen, folgen Sie bitte der
								Anleitung in Ziffer 2.2.
							</p>
							<p className="mb-6 text-black">
								Sie können auch – je nach den Konfigurationsmöglichkeiten Ihres Browsers – jederzeit das Setzen von Tracking-Cookies (wie wir sie mit Ihrer Einwilligung für die
								Nutzungsanalyse einsetzen) in Ihren Browser-Einstellungen ganz oder teilweise deaktivieren und/oder Cookies über Ihr Browser-Menü aus Ihrem Speicher löschen. Zudem
								können Sie ein Plugin für Ihren Browser installieren, das Ihre Privatsphäre schützt und die Möglichkeit bietet, das Setzen von Tracking- und/oder anderen Cookies zu
								verhindern.
							</p>
							<p className="mb-6 text-black">
								Folgende Cookies werden bei Ihrem Besuch unserer Website gesetzt (die nicht als notwendig gekennzeichneten Cookies werden nur gesetzt, sofern Sie einwilligen):
							</p>
							<p className=" text-black font-bold">Cookies</p>
							<table className="table-fixed border-collapse text-sm text-left overflow-x-scroll block">
								<thead>
									<tr>
										<th className="font-bold align-top">Cookie </th>
										<th className="font-bold align-top">Kategorie </th>
										<th className="font-bold align-top">Verarbeitung durch </th>
										<th className="font-bold align-top">Zweck </th>
										<th className="font-bold align-top">Speicherdauer </th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td className="align-top border-t-[1px] border-gray/30">
											<span className=" hidden sm:inline-block font-bold">Cookie </span> _pk_id
										</td>
										<td className="align-top border-t-[1px] border-gray/30">
											<span className=" hidden sm:inline-block font-bold">Kategorie </span> Tracking (nicht notwendige Cookies)
										</td>
										<td className="align-top border-t-[1px] border-gray/30">
											<span className=" hidden sm:inline-block font-bold">Verarbeitung durch </span> Piwik (s. 2.2)
										</td>
										<td className="align-top border-t-[1px] border-gray/30">
											<span className=" hidden sm:inline-block font-bold">Zweck </span> Piwik Tracking – hinterlegt eine seitenspezifische ID zur Zuordnung des Datensatzes
										</td>
										<td className="align-top border-t-[1px] border-gray/30">
											<span className=" hidden sm:inline-block font-bold">Speicherdauer </span> 12 Monate
										</td>
									</tr>
									<tr>
										<td className="align-top border-t-[1px] border-gray/30">
											<span className=" hidden sm:inline-block font-bold">Cookie </span> _pk_ses
										</td>
										<td className="align-top border-t-[1px] border-gray/30">
											<span className=" hidden sm:inline-block font-bold">Kategorie </span>
											<span className=" hidden sm:inline-block"> Tracking (nicht notwendige Cookies) </span>
										</td>
										<td className="align-top border-t-[1px] border-gray/30">
											<span className=" hidden sm:inline-block font-bold">Verarbeitung durch </span> Piwik (s. 2.2)
										</td>
										<td className="align-top border-t-[1px] border-gray/30">
											<span className=" hidden sm:inline-block font-bold">Zweck </span> Piwik Tracking – hinterlegt eine ID für die aktuelle Browser-Session des Nutzers zur
											Zuordnung des Datensatzes
										</td>
										<td className="align-top border-t-[1px] border-gray/30">
											<span className=" hidden sm:inline-block font-bold">Speicherdauer </span> 30 Minuten
										</td>
									</tr>
									<tr>
										<td className="align-top border-t-[1px] border-gray/30">
											<span className=" hidden sm:inline-block font-bold">Cookie </span> stg_last_interaction
										</td>
										<td className="align-top border-t-[1px] border-gray/30">
											<span className=" hidden sm:inline-block font-bold">Kategorie </span>
											<span className=" hidden sm:inline-block"> Tracking (nicht notwendige Cookies) </span>
										</td>
										<td className="align-top border-t-[1px] border-gray/30">
											<span className=" hidden sm:inline-block font-bold">Verarbeitung durch </span> Piwik (s. 2.2)
										</td>
										<td className="align-top border-t-[1px] border-gray/30">
											<span className=" hidden sm:inline-block font-bold">Zweck </span> Piwik Tracking –speichert den Moment der letzten Interaktion des Nutzers
										</td>
										<td className="align-top border-t-[1px] border-gray/30">
											<span className=" hidden sm:inline-block font-bold">Speicherdauer </span> 12 Monate
										</td>
									</tr>
									<tr>
										<td className="align-top border-t-[1px] border-gray/30">
											<span className=" hidden sm:inline-block font-bold">Cookie </span> stg_returning_visitor
										</td>
										<td className="align-top border-t-[1px] border-gray/30">
											<span className=" hidden sm:inline-block font-bold">Kategorie </span>
											<span className=" hidden sm:inline-block"> Tracking (nicht notwendige Cookies) </span>
										</td>
										<td className="align-top border-t-[1px] border-gray/30">
											<span className=" hidden sm:inline-block font-bold">Verarbeitung durch </span> Piwik (s. 2.2)
										</td>
										<td className="align-top border-t-[1px] border-gray/30">
											<span className=" hidden sm:inline-block font-bold">Zweck </span> Piwik Tracking – speichert, ob es sich um einen wiederkehrenden Nutzer handelt
										</td>
										<td className="align-top border-t-[1px] border-gray/30">
											<span className=" hidden sm:inline-block font-bold">Speicherdauer </span> 12 Monate
										</td>
									</tr>
									<tr>
										<td className="align-top border-t-[1px] border-gray/30">
											<span className=" hidden sm:inline-block font-bold">Cookie </span> stg_traffic_source_priority
										</td>
										<td className="align-top border-t-[1px] border-gray/30">
											<span className=" hidden sm:inline-block font-bold">Kategorie </span>
											<span className=" hidden sm:inline-block"> Tracking (nicht notwendige Cookies) </span>
										</td>
										<td className="align-top border-t-[1px] border-gray/30">
											<span className=" hidden sm:inline-block font-bold">Zweck </span> Piwik (s. 2.2)
										</td>
										<td className="align-top border-t-[1px] border-gray/30">
											<span className=" hidden sm:inline-block font-bold">Verarbeitung durch </span> Piwik Tracking – speichert, über welche Quelle (Kampagne, Suchmaschine, direkte
											Eingabe) der Nutzer auf die Website kam
										</td>
										<td className="align-top border-t-[1px] border-gray/30">
											<span className=" hidden sm:inline-block font-bold">Speicherdauer </span> 30 Minuten
										</td>
									</tr>
									<tr>
										<td className="align-top border-t-[1px] border-gray/30">
											<span className=" hidden sm:inline-block font-bold">Cookie </span> stg_optout
										</td>
										<td className="align-top border-t-[1px] border-gray/30">
											<span className=" hidden sm:inline-block font-bold">Kategorie </span>
											<span className=" hidden sm:inline-block"> Tracking (nicht notwendige Cookies) </span>
										</td>
										<td className="align-top border-t-[1px] border-gray/30">
											<span className=" hidden sm:inline-block font-bold">Zweck </span> Piwik (s. 2.2)
										</td>
										<td className="align-top border-t-[1px] border-gray/30">
											<span className=" hidden sm:inline-block font-bold">Verarbeitung durch </span> Piwik Tracking – speichert, dass nicht getrackt wird
										</td>
										<td className="align-top border-t-[1px] border-gray/30">
											<span className=" hidden sm:inline-block font-bold">Speicherdauer </span> 12 Monate
										</td>
									</tr>
									<tr>
										<td className="align-top border-t-[1px] border-gray/30">
											<span className=" hidden sm:inline-block font-bold">Cookie </span> hl
										</td>
										<td className="align-top border-t-[1px] border-gray/30">
											<span className=" hidden sm:inline-block font-bold">Kategorie </span> Sprache (notwendiges Cookie)
										</td>
										<td className="align-top border-t-[1px] border-gray/30">
											<span className=" hidden sm:inline-block font-bold">Zweck </span> Storck
										</td>
										<td className="align-top border-t-[1px] border-gray/30">
											<span className=" hidden sm:inline-block font-bold">Verarbeitung durch </span> Lokale Settings – speichert Sprache und Domain der jeweiligen Seite (z. B.:
											„de_CH“ oder „en_COM“)
										</td>
										<td className="align-top border-t-[1px] border-gray/30">
											<span className=" hidden sm:inline-block font-bold">Speicherdauer </span> 12 Monate
										</td>
									</tr>
									<tr>
										<td className="align-top border-t-[1px] border-gray/30">
											<span className=" hidden sm:inline-block font-bold">Cookie </span> hideCookieNotification
										</td>
										<td className="align-top border-t-[1px] border-gray/30">
											<span className=" hidden sm:inline-block font-bold">Kategorie </span> Cookie-Benachrichtigung (notwendiges Cookie)
										</td>
										<td className="align-top border-t-[1px] border-gray/30">
											<span className=" hidden sm:inline-block font-bold">Zweck </span> Storck
										</td>
										<td className="align-top border-t-[1px] border-gray/30">
											<span className=" hidden sm:inline-block font-bold">Verarbeitung durch </span> Cookie Notification – wird gesetzt, damit die Cookie Notification nicht bei
											jedem Website-Besuch erneut angezeigt wird
										</td>
										<td className="align-top border-t-[1px] border-gray/30">
											<span className=" hidden sm:inline-block font-bold">Speicherdauer </span> 24 Stunden
										</td>
									</tr>
									<tr>
										<td className="align-top border-t-[1px] border-gray/30">
											<span className=" hidden sm:inline-block font-bold">Cookie </span> hideLocationNotification
										</td>
										<td className="align-top border-t-[1px] border-gray/30">
											<span className=" hidden sm:inline-block font-bold">Kategorie </span> Länderauswahl (notwendiges Cookie)
										</td>
										<td className="align-top border-t-[1px] border-gray/30">
											<span className=" hidden sm:inline-block font-bold">Zweck </span> Storck
										</td>
										<td className="align-top border-t-[1px] border-gray/30">
											<span className=" hidden sm:inline-block font-bold">Verarbeitung durch </span> Location Notification – wird gesetzt, damit der Country Selector nicht bei
											jedem Besuch der storck.com erneut angezeigt wird
										</td>
										<td className="align-top border-t-[1px] border-gray/30">
											<span className=" hidden sm:inline-block font-bold">Speicherdauer </span> Nur während der Browser-Sitzung (sog. Session Cookie).
										</td>
									</tr>
								</tbody>
							</table>
							<p className="font-bold text-black">2.4 Anfragen über das Kontaktformular oder auf sonstigem Kommunikationsweg</p>
							<p className="mb-6 text-black">
								Wenn Sie unser Kontaktformular nutzen, verarbeiten wir Ihre Nachricht, Ihren Vor- und Zunamen, Ihre E-Mail-Adresse, Ihr Land und – soweit Sie uns diese Angaben
								mitteilen – Ihre Telefonnummer, Ihre Anschrift und Ihr Unternehmen. Wir verarbeiten diese Daten zur Beantwortung Ihrer Anfrage. Eine Übermittlung Ihrer
								personenbezogenen Daten an Dritte findet dabei nur statt, wenn dies notwendig ist, um Ihre Anfrage zu beantworten (z. B. wenn Sie Informationen über eine andere
								Gesellschaft der Storck Gruppe anfragen). Die Rechtsgrundlage für die Verarbeitung ist Artikel 6 Abs. 1 b DSGVO, sofern die Bearbeitung Ihres Anliegens einen mit
								Ihnen bestehenden Vertrag betrifft und zur Vertragserfüllung erforderlich ist oder wenn Ihre Anfrage sich auf die Durchführung vorvertraglicher Maßnahmen richtet.
								Im Übrigen ist die Rechtsgrundlage unser berechtigtes Interesse an der Bereitstellung eines Kontaktformulars und der Beantwortung allgemeiner Anliegen (Art. 6 Abs.
								1 f DSGVO). Entsprechend diesem Absatz verarbeiten wir Ihre Daten auch, wenn Sie sich postalisch oder auf sonstigem Weg mit einer Anfrage an uns wenden.
							</p>
							<p className="font-bold text-black">2.5 Teilnahme an Gewinnspielen und Aktionen</p>
							<p className="mb-6 text-black">
								Wenn Sie an einem von uns veranstalteten Gewinnspiel oder einer Aktion teilnehmen, verarbeiten wir die von Ihnen im Rahmen der Teilnahme angegebenen Daten
								(insbesondere Vor- und Zuname und Anschrift bzw. Kontaktdaten) zur Durchführung des mit Ihnen geschlossenen (Gewinnspiel-)Vertrags nach den jeweils geltenden
								Teilnahmebedingungen. Rechtsgrundlage ist die Verarbeitung zur Vertragserfüllung nach Artikel 6 Abs. 1 b DSGVO. Dieser Abschnitt gilt unabhängig davon, ob Sie durch
								Ausfüllen eines Web-Formulars, postalisch oder auf sonstigem Weg an dem Gewinnspiel oder einer Aktion teilnehmen.
							</p>
							<p className="mb-6 text-black">
								Für die Organisation und Durchführung eines Gewinnspiels oder einer Aktion (z. B. zur Erhebung der Teilnehmerdaten über ein Webformular, zur Ziehung von Gewinnern,
								zur Abwicklung der Gewinnausgabe oder Erfüllung der Aktion) setzen wir mitunter Dienstleister ein. Jeder Dienstleister wird von uns sorgfältig ausgewählt und ist
								nach den geltenden datenschutzrechtlichen Anforderungen als weisungsgebundener Auftragsverarbeiter für uns tätig und zur Einhaltung des Datenschutzes verpflichtet.
							</p>
							<p className="font-bold text-black">2.6 Social Media Links</p>
							<p className="mb-6 text-black">
								Wir verzichten auf unseren Webseiten bewusst auf die Einbindung sogenannter Social Plugins, die bereits beim Laden der Webseite ohne Ihr weiteres Zutun Daten an die
								Betreiber der sozialen Netzwerke übertragen. Unsere Webseite enthält lediglich Links zu sozialen Netzwerken (z. B. Facebook und Twitter), d. h. beim Besuch unserer
								Webseite werden keine Daten an die Anbieter der sozialen Netzwerke weitergegeben. Wenn Sie einen Link zu einem der Betreiber der sozialen Netzwerke aufrufen, können
								aber – wie bei jedem Internetlink – ggf. Informationen (z. B. der verwendete Browser) an die Anbieter übermittelt werden. Haben Sie einen Account bei dem
								betreffenden sozialen Netzwerk und sind Sie bei Aufruf des Links eingeloggt, kann der Anbieter zudem den Besuch unserer Webseite Ihrem Social Media Account direkt
								zuordnen. Sie können dies verhindern, indem Sie sich vor Aufruf des Links ausloggen.
							</p>
							<p className="mb-6 text-black">
								Bitte beachten Sie, dass wir auf den Inhalt der verlinkten Seiten und die dort stattfindende Datenverarbeitung keinerlei Einfluss haben. Zweck und Umfang der
								Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch die sozialen Netzwerke sowie die Einstellungsmöglichkeiten zum Schutze Ihrer Privatsphäre
								entnehmen Sie bitte den Datenschutzhinweisen des jeweiligen Anbieters.
							</p>
						</AccordionItem>

						<AccordionItem headline={"Empfänger; Übermittlung in Drittländer"} num="3.">
							<p className="mb-6 text-black">
								Unsere Website wird gehostet bei der direct services Gütersloh GmbH, Reinhard-Mohn-Str. 300, 33333 Gütersloh, Deutschland, die als unser Auftragsverarbeiter
								vertraglich zur ordnungsgemäßen Datenverarbeitung verpflichtet ist.
							</p>
							<p className="mb-6 text-black">
								Im Rahmen von Gewinnspielen und Aktionen greifen wir gegebenenfalls auf die technische Unterstützung von weiteren Dienstleistern wie Internetagenturen zurück, die
								für uns entsprechende Webseitenbereiche erstellen und technisch betreiben. Diese Agenturen sind von uns ordnungsgemäß als Auftragsverarbeiter verpflichtet. Dabei
								können iframes oder vergleichbare Seitenelemente auf unserer Website eingesetzt oder es kann auf eigenständige Websites (Microsites) verlinkt werden, die direkt vom
								Server eines für uns tätigen Auftragsverarbeiters geladen werden. In diesem Fall erhält der jeweilige Auftragsverarbeiter die beim Laden dieser Elemente bzw.
								Websites von Ihrem Browser übermittelten Daten (Ziffer 2.1) sowie ggf. weitere Daten, die Sie zur Teilnahme am Gewinnspiel oder der Aktion über Web-Formulare oder
								auf sonstigem Weg bereitstellen.
							</p>
							<p className="mb-6 text-black">
								Soweit nicht in dieser Datenschutzerklärung angegeben, übermitteln wir Ihre Daten grundsätzlich nicht an Dritte. Eine Weitergabe erfolgt ausnahmsweise dann, wenn
								wir aufgrund gesetzlicher Bestimmungen und/oder behördlicher oder gerichtlicher Anordnungen zu einer Datenweitergabe verpflichtet sind.
							</p>
							<p className="mb-6 text-black">
								Eine Weitergabe Ihrer Daten an eine Stelle in einem Drittland (d. h. außerhalb des Europäischen Wirtschaftsraums) oder eine internationale Organisation findet
								grundsätzlich nicht statt. Soweit eine solche Weitergabe ausnahmsweise erforderlich ist, insbesondere weil eine von Ihnen gestellte Anfrage an eine unserer
								Gesellschaften in einem Drittland weiterzuleiten ist (Ziffer 2.4), stellen wir sicher, dass die Voraussetzungen von Kapitel V der DSGVO erfüllt sind und damit ein
								angemessenes Datenschutzniveau beim Empfänger gewährleistet ist. Hierfür verwenden wir bei Bedarf die EU-Standardvertragsklauseln für Datentransfers in Drittländer.
							</p>
						</AccordionItem>

						<AccordionItem headline={"Dauer der Speicherung"} num="4.">
							<p className="mb-6 text-black">
								{" "}
								Die in den Logfiles (Ziffer 2.1) gespeicherten personenbezogenen Informationen werden nach maximal sieben Tagen gelöscht bzw. anonymisiert.{" "}
							</p>
							<p className="text-black">
								Im Übrigen löschen wir Ihre personenbezogenen Daten, sobald sie für die mit der Verarbeitung verfolgten Zwecke nicht mehr benötigt werden und soweit keine
								gesetzlichen Aufbewahrungspflichten entgegenstehen. Relevante Aufbewahrungsfristen nach dem deutschen Steuer- und Handelsrecht betragen sechs Jahre für alle
								Geschäftsbriefe (auch elektronische) und zehn Jahre für alle Informationen, die für Steuer und Buchhaltung relevant sind.
							</p>
						</AccordionItem>

						<AccordionItem headline="Daten&shy;sicherheit" num="5.">
							<p className="mb-6 text-black">
								Storck hat die notwendigen technischen und organisatorischen Maßnahmen ergriffen, um die von Ihnen zur Verfügung gestellten personenbezogenen Daten vor Verlust,
								Zerstörung, Manipulation und unberechtigtem Zugriff zu schützen. Unsere Mitarbeiter und alle an der Datenverarbeitung beteiligten Personen sind zur Einhaltung
								datenschutzrelevanter Gesetze und dem vertraulichen Umgang mit personenbezogenen Daten verpflichtet. Unsere Mitarbeiter sind entsprechend geschult. Sowohl interne
								als auch externe Prüfungen sichern die Einhaltung aller datenschutzrelevanten Prozesse bei Storck.
							</p>
							<p className="text-black">
								Zum Schutz der personenbezogenen Daten unserer Nutzer benutzen wir ein sicheres Online-Übertragungsverfahren, die sogenannte „Secure Socket Layer“
								(SSL)-Übertragung. Sie erkennen dies daran, dass an den Adressbestandteil http:// ein „s“ angehängt („https://“) bzw. ein grünes, geschlossenes Schloss-Symbol
								angezeigt wird. Durch Anklicken des Symbols erhalten Sie Informationen über das verwendete SSL-Zertifikat. Die Darstellung des Symbols ist abhängig von der von
								Ihnen eingesetzten Browserversion. Die SSL-Verschlüsselung gewährleistet die verschlüsselte und vollständige Übertragung Ihrer Daten.
							</p>
						</AccordionItem>

						<AccordionItem headline="Ihre Rechte bezüglich personen&shy;bezogener Daten" num="6.">
							<p className="mb-6 text-black">
								{" "}
								Das Datenschutzrecht gewährt Ihnen eine Reihe von Rechten in Bezug auf Daten, die Ihre Person betreffen (sogenannte Betroffenenrechte).{" "}
							</p>
							<p className="font-bold text-black">6.1 Recht auf Auskunft </p>
							<p className="mb-6 text-black">
								Sie haben das Recht, von uns eine Bestätigung darüber zu verlangen, ob wir Ihre persönlichen Daten verarbeiten. Ist dies der Fall, so haben Sie ein Recht auf
								Auskunft über diese Daten. Die Einzelheiten über Ihr Auskunftsrecht können Sie in Artikel 15 DSGVO nachlesen.
							</p>
							<p className="font-bold text-black">6.2 Recht auf Berichtigung und Vervollständigung </p>
							<p className="mb-6 text-black">
								Sie haben gemäß Artikel 16 DSGVO das Recht, von uns unverzüglich die Berichtigung der Sie betreffenden unrichtigen persönlichen Daten und die Vervollständigung
								unvollständiger persönlicher Daten zu verlangen.
							</p>
							<p className="font-bold text-black">6.3 Recht auf Löschung </p>
							<p className="mb-6 text-black">
								Sie haben das Recht, von uns die unverzügliche Löschung Ihrer persönlichen Daten zu verlangen, sofern einer der in Artikel 17 DSGVO genannten Gründe vorliegt. Ein
								solcher Grund für die unverzügliche Löschung kann zum Beispiel gegeben sein, wenn die persönlichen Daten für die Bearbeitung Ihres Anliegens nicht mehr notwendig
								sind oder Sie der Datenverarbeitung widersprochen haben.
							</p>
							<p className="font-bold text-black">6.4 Recht auf Einschränkung der Verarbeitung </p>
							<p className="mb-6 text-black">
								Sie haben das Recht, von uns die Einschränkung der Verarbeitung Ihrer persönlichen Daten zu verlangen, wenn eine der in Artikel 18 DSGVO genannten Voraussetzungen
								gegeben ist. So besteht zum Beispiel in dem Fall, dass Sie die Richtigkeit der Daten bestreiten, das Recht auf Einschränkung der Verarbeitung für die Dauer, die es
								uns ermöglicht, die Richtigkeit der Daten zu überprüfen.
							</p>
							<p className="font-bold text-black">6.5 Recht auf Widerruf der Einwilligung </p>
							<p className="mb-6 text-black">
								Sie haben gemäß Artikel 7 Abs.3 DSGVO das Recht, eine erteilte Einwilligung in die Verarbeitung Ihrer persönlichen Daten jederzeit zu widerrufen. Der Widerruf gilt
								nur für zukünftige Datenverarbeitungen, das heißt, die bis zum erfolgten Widerruf stattgefundene Datenverarbeitung bleibt in diesem Fall rechtmäßig.
							</p>
							<p className="font-bold text-black">6.6 Widerspruchsrecht </p>
							<p className="font-bold text-black">
								Soweit sich die Datenverarbeitung durch uns auf ein berechtigtes Interesse gemäß Artikel 6 Abs. 1 f DSGVO stützt (siehe dazu oben die zu jeder Datenverarbeitung
								angegebene Rechtsgrundlage), haben Sie ein Widerspruchsrecht gemäß Artikel 21 Abs. 1 DSGVO. Das bedeutet, Sie können der Verarbeitung Ihrer persönlichen Daten durch
								uns jederzeit widersprechen, sofern Gründe vorliegen, die sich aus Ihrer besonderen Situation ergeben. Ihre persönlichen Daten werden nach Ihrem eingelegten
								Widerspruch nicht mehr von uns verarbeitet, es sei denn, es liegen nachweisbar zwingende schutzwürdige Gründe für die Verarbeitung vor, die Ihre Interessen, Rechte
								und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
								<br />
							</p>
							<p className="font-bold text-black">
								Sofern wir personenbezogene Daten verarbeiten, um Direktwerbung zu betreiben, können Sie dem jederzeit nach Artikel 21 Abs. 2 DSGVO widersprechen. In diesem Fall
								werden wir Ihre Daten nicht mehr zu diesem Zweck verarbeiten.
							</p>
							<br />
							<p className="font-bold text-black">6.7 Recht zur Beschwerde </p>
							<p className="text-black">
								Sie haben ferner ein Recht zur Beschwerde bei der zuständigen Datenschutzaufsichtsbehörde. Soweit Sie Fragen oder Beschwerden zum Datenschutz bei Storck haben,
								empfehlen wir Ihnen jedoch, sich zunächst an unseren Datenschutzbeauftragten zu wenden (Ziffer 1).
							</p>
						</AccordionItem>

						<AccordionItem headline={"Keine Pflicht zur Bereitstellung von Daten"} num="7.">
							<p className="text-black">
								Sie sind nicht gesetzlich oder vertraglich verpflichtet, Storck personenbezogene Daten für den Besuch der Webseite bereitzustellen. Für die Nutzung bestimmter
								Bereiche (insbesondere das Kontaktformular oder Gewinnspiele) kann die Angabe Ihrer Daten jedoch zur Bearbeitung Ihrer Anfrage bzw. der Durchführung des
								Gewinnspiels erforderlich sein.
							</p>
						</AccordionItem>

						<AccordionItem headline="Keine automatisierte Einzel&shy;entscheidung" num="8.">
							<p className="text-black"> Storck verwendet Ihre personenbezogenen Daten nicht für automatisierte Einzelentscheidungen. </p>
						</AccordionItem>

						<AccordionItem headline="Änderung der Daten&shy;schutz&shy;erklärung" num="9.">
							<p className="text-black">
								Neue rechtliche Vorgaben, unternehmerische Entscheidungen oder die technische Entwicklung erfordern gegebenenfalls Änderungen in unserer Datenschutzerklärung. Die
								Datenschutzerklärung wird dann entsprechend angepasst. Die aktuelle Version finden Sie immer auf unserer Website.
							</p>
						</AccordionItem>
					</div>
				</div>
			</>
		</div>
	)
}
export default Privacy
