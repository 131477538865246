export function updateIframeScrollTo(value) {
	const win: any = window
	win.parent.postMessage({ scrollAnchor: value }, "*")

	// console.log({ scrollAnchor: value })
}
export function updateIframeHeight(_key?) {
	try {
		const win: any = window
		const doc: any = document
		var body = doc.body
		var html = doc.documentElement
		var height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)

		win.parent.postMessage({ iframeHeight: height }, "*")
		// console.log({ iframeHeight: height }, _key)
	} catch (error) {}
}
export function getRandomInt(max) {
	return Math.floor(Math.random() * max)
}
