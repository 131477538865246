import axios from 'axios';
import { getEnvConfig } from '../env';

const backendValidationMapping = {
    "email" : "isInvalid_email",
    "firstName" : "isInvalid_firstname",
    "lastName" : "isInvalid_lastname",
    "street" : "isInvalid_street",
    "city" : "isInvalid_city",
    "zip" : "isInvalid_zip",
    "jumpHouseLocation" : "isInvalid_jumphouselocation",
};

const env = getEnvConfig()

/**
 *
 * @param address
 * @param cep
 * @param orderItems
 * @param updateFormState Callback function to update the form state according to order result
 * @param scrollToErrorField
 */
export const createOrder = (address, extID, updateFormState, scrollToErrorField, navigate, sessionID) => {
        const data = {
            address:  address,
            extID: extID,
            locale: "de-DE",
            sessionID: sessionID,
        };
    
    axios.post( env.apibase + 'order.php?cv='+Date.now(), data )
        .then(_ => {
            try {
                const win: any = window
                win.dataLayer = win.dataLayer || []
                win.dataLayer.push({event: 'n2_gewinner', sto_form_submit: true, sto_form_message: '', sto_gewinn: '1'});
            } catch (error) {}
            
            navigate("/kontakt/bestaetigung")
        })
        .catch( (error) => {
            console.log(error)
            try {
                const win: any = window
                win.dataLayer = win.dataLayer || []
                win.dataLayer.push({event: 'n2_gewinner', sto_form_submit: false, sto_form_message: 'email error', sto_gewinn: '1'});
            } catch (error) {}

            if(error.response && (error.response.status >= 400 && error.response.status < 500) ){ // for 40X cases
                parseOrderErrors(error.response.data.errors, updateFormState, scrollToErrorField);
            }
            updateFormState("UPLOADINCOMPLETE");
        })
}

/**
 *
 * @param errors
 * @param updateFormState
 * @param scrollToErrorField
 */
const parseOrderErrors = (errors, updateFormState, scrollToErrorField) => {
    if (errors){

        let tmp_errorCollection = {};

        for (const key in errors.children) {
            if (errors.children[key].errors && backendValidationMapping[key]) {
                tmp_errorCollection[backendValidationMapping[key] + "_errMsg"] = errors.children[key].errors.join(" ");
                tmp_errorCollection[backendValidationMapping[key]]= true;
            }
        }
        
        updateFormState("PARSE_ORDER_ERRORS", tmp_errorCollection);
        scrollToErrorField();
    }
}
