import { useEffect } from "react"
import Fruchtgummi from "../components/Fruchtgummi"
import { updateIframeHeight, updateIframeScrollTo } from "../helper"

function Promoover() {
	useEffect(() => {
		updateIframeScrollTo(0)
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
		updateIframeHeight()
		// eslint-disable-next-line
	}, [])

	return (
		<div className="bg-[url('/src/bgimages/sun.jpg')] bg-no-repeat bg-cover bg-top">
			<section className="container max-w-xl ">
				<img src={process.env.PUBLIC_URL + "/images/nimm2header.png"} className=" w-full mb-12" alt="" />
			</section>

			<div className="pb-6 container text-center -mb-12">
				<h2 className="h2">DAS GEWINNSPIEL IST LEIDER BEENDET!</h2>

				<p className="mb-4">Unser Gewinnspiel „der Sommer lacht mit nimm2” ist seit dem 02.09.2024 beendet.</p>
				<p className="mb-4">
					Du hast noch Fragen zur Aktion? Kein Problem! Kontaktiere uns einfach per E-Mail unter{" "}
					<a
						href="mailto:info@nimm2-gewinnspiel.de"
						onClick={() => {
							try {
								// eslint-disable-next-line
								let dataLayer = window["dataLayer"] || []
								dataLayer.push({ event: "promoover", link: "mail" })
							} catch (e) {}
						}}
						className="underline"
					>
						info@nimm2-gewinnspiel.de
					</a>
					.
				</p>
				<p className="mb-4">Besuche uns gerne auf nimm2.de, um nichts zu verpassen.</p>
				<p className="mb-4">
					Wir freuen uns auf DICH! <br />
					Dein nimm2 Team
				</p>
			</div>
			<Fruchtgummi />
		</div>
	)
}
export default Promoover
