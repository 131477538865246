import { useEffect, useState } from "react"
import Fruchtgummi from "../components/Fruchtgummi"
import { updateIframeHeight, updateIframeScrollTo } from "../helper"
import { useNavigate } from "react-router-dom"

function Promosoon() {
	const [clickCout, setclickCout] = useState(0)
	const navigate = useNavigate()

	useEffect(() => {
		updateIframeScrollTo(0)
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
		updateIframeHeight()
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		if (clickCout === 10) {
			navigate("/memory")
		}
		// eslint-disable-next-line
	}, [clickCout])

	return (
		<>
			<div className="bg-[url('/src/bgimages/sun.jpg')] bg-no-repeat bg-cover bg-top  pb-32">
				<section className="container max-w-xl ">
					<img src={process.env.PUBLIC_URL + "/images/nimm2header.png"} className=" w-full mb-12" alt="" onLoad={() => updateIframeHeight("nimm2header.png")} />
				</section>

				<div className="pb-6 container text-center ">
					<h2 className="h2">AB DEM 17.06.2024 GEHT'S LOS!</h2>
					<div className="max-w-3xl mx-auto">
						<img src={process.env.PUBLIC_URL + "/images/promosoonHL.png"} className=" w-full " alt="" onLoad={() => updateIframeHeight("promosoonHL.png")} />
					</div>
					<p className=" md:text-2xl drop-shadow-md">Tägliche Chance auf tolle Gewinne!</p>
				</div>
			</div>
			<div
				className="h-0"
				onClick={() => {
					let newClickCout = clickCout + 1
					setclickCout(newClickCout)
				}}
			>
				<img
					src={process.env.PUBLIC_URL + "/images/fruchtgummi.png"}
					className=" w-full max-w-md relative mx-auto z-10 -translate-y-1/2 "
					alt=""
					onLoad={() => updateIframeHeight("fruchtgummi")}
				/>
			</div>
			<p className=" text-center font-Museo text-white py-32 promosoonGradient">Teilnahme vom 17.06.2024 bis zum 01.09.2024 ab 18 Jahren, nur Online und in Deutschland möglich.</p>
		</>
	)
}
export default Promosoon
