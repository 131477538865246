import { useEffect, useState } from "react"
import { WEEKPLAN, WeekPlanProps } from "../../config"
import ListDayItem from "./ListDayItem"

import { Pagination, A11y } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import "swiper/css"
import "swiper/css/pagination"
const ListDays = () => {
	const [sortedWeekPlan, setsortedWeekPlan] = useState<WeekPlanProps[] | null>(null)

	useEffect(() => {
		const tomorrow = (new Date().getDay() + 1) % 7 // Get the current day
		let weekdays = WEEKPLAN
		const sortedWeekdays = [...weekdays.slice(tomorrow), ...weekdays.slice(0, tomorrow)]

		sortedWeekdays.sort((a: any, b: any) => {
			if (a.getDay < tomorrow && b.getDay >= tomorrow) {
				return 1 // a should come after b
			} else if (a.getDay >= tomorrow && b.getDay < tomorrow) {
				return -1 // a should come before b
			} else {
				return a.getDay - b.getDay // sort normally
			}
		})
		setsortedWeekPlan(sortedWeekdays)
		// eslint-disable-next-line
	}, [])

	return (
		<section className="max-w-6xl mx-auto">
			<Swiper
				modules={[Pagination, A11y]}
				slidesPerView={3}
				breakpoints={{
					768: {
						slidesPerView: 5,
					},
				}}
				pagination={{
					clickable: true,
					renderBullet: function (index, className) {
						return '<div class="' + className + '" style="width:22px; background:transparent; "><img width="22px" height="22px" src="/images/icon-dots-active.svg" alt="" /></div>'
					},
				}}
			>
				{sortedWeekPlan &&
					sortedWeekPlan.map(day => (
						<SwiperSlide>
							<div className="pb-[45%] sm:pb-[30%] px-[5%]">
								<ListDayItem day={day} />
							</div>
						</SwiperSlide>
					))}
			</Swiper>
		</section>
	)
}

export default ListDays
