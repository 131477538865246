const Reducer = (state, action) => {
	switch (action.type) {
		case "SET_ENTRY":
			return {
				...state,
				[action.key]: action.value,
			}

		default:
			return state
	}
}

export default Reducer
