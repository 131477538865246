import React, { useEffect } from "react"
import { updateIframeHeight, updateIframeScrollTo } from "../helper"
import Fruchtgummi from "../components/Fruchtgummi"

const Terms: React.FC = () => {
	useEffect(() => {
		updateIframeScrollTo(0)
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})

		updateIframeHeight()
		// eslint-disable-next-line
	}, [])

	return (
		<div className="contentGradient">
			<div className="container max-w-[800px] mx-auto px-4  py-12">
				<h2 className="h2 ">Teilnahme&shy;bedingungen </h2>
				<h3 className="h3 ">„nimm2 - Der Sommer lacht“ 2024</h3>
				<h3 className="h3 py-4">1. Allgemeine Bedingungen</h3>
				<p className="mb-4 text-white">
					Die August Storck KG, Waldstraße 27, 13403 Berlin (im Folgenden „Storck“), veranstaltet vom 17.06.2024 bis zum 01.09.2024 die Promotion „nimm2 - der Sommer lacht“ in
					Deutschland. Es handelt sich hierbei um ein Online-Gewinnspiel auf der Internetseite{" "}
					<a href="https://www.dersommerlacht.de" target="_blank" rel="noopener noreferrer" className="underline">
						www.dersommerlacht.de
					</a>
					.
				</p>
				<h3 className="h3 py-4">2. Teilnahmeberechtigung</h3>
				<p className="mb-4 text-white">
					Mit der Teilnahme an dem Gewinnspiel erklärt sich der Teilnehmer mit diesen Teilnahmebedingungen einverstanden. Teilnehmen können ausschließlich natürliche Personen ab 18
					Jahren. Für die Teilnahme ist ein Internetzugang erforderlich. Die Teilnahme ist nur innerhalb Deutschlands möglich. Eine automatisierte Teilnahme ist nicht zulässig.
					Eine Mehrfachteilnahme ist möglich, jedoch darf jeder Teilnehmer nur einmalig gewinnen.
				</p>
				<p className="mb-4 text-white">
					Der Veranstalter behält sich das Recht vor, Personen von der Teilnahme auszuschließen, die gegen die Teilnahmebedingungen verstoßen oder bei denen der Verdacht besteht,
					dass sie sich bei der Teilnahme unerlaubter Hilfsmittel bedienen oder in sonstiger Weise versuchen, sich oder Dritten durch Manipulation Vorteile zu verschaffen. Der
					Ausschluss kann in diesem Fall auch nach der Teilnahme erfolgen und hat die Verweigerung bzw. Rückforderung des Gewinns zur Folge.
				</p>
				<h3 className="h3 py-4">3. Teilnahme und Ablauf des Gewinnspiels </h3>
				<p className="mb-4 text-white">
					Um an der Verlosung des Gewinnspiels teilzunehmen, muss der Teilnehmer im Aktionszeitraum vom 17.06.2024 bis 01.09.2024 auf der Aktionswebseite unter{" "}
					<a href="https://www.dersommerlacht.de" target="_blank" rel="noopener noreferrer" className="underline">
						www.dersommerlacht.de
					</a>{" "}
					zunächst das Captcha ausfüllen und anschließend zwei der zwölf abgebildeten Aktionsfelder anklicken. Die Gewinne werden nach dem Zufallsprinzip vergeben. Wenn der
					Teilnehmer gewonnen hat, erscheinen hinter beiden angeklickten Aktionsfeldern dieselbe Abbildung des Gewinns. Der Teilnehmer erfährt sofort, ob und was er gewonnen hat.
					Erscheinen hinter einem oder beiden Aktionsfeldern keine Abbildung des Gewinns oder nicht dieselbe Abbildung, hat der Teilnehmer nicht gewonnen. Eine mehrfache Teilnahme
					ist möglich.
				</p>
				<p className="mb-4 text-white">
					Im Gewinnfall gibt der Teilnehmer die für die Gewinnabwicklung notwendigen Kontaktdaten ein und sendet das Gewinnspiel-Kontaktformular ab. Eine Übersendung der Sachpreise
					erfolgt nur nach erfolgreicher Übermittlung der korrekten, personenbezogenen Daten an Storck.
				</p>
				<h3 className="h3 py-4">4. Gewinne & Gewinnabwicklung</h3>
				<p className="mb-4 text-white">
					Die Gewinne sind den Wochentagen zugeordnet und werden jeweils an dem entsprechenden Wochentag verlost. An jedem Tag gibt es mindestens einen Gewinnmoment.
				</p>
				<p className="mb-4 text-white">Zu gewinnen gibt es insgesamt:</p>
				<h4 className="h4 text-base pb-0">Gewinne an Montagen</h4>
				<p className="mb-4 text-white">An allen Montagen im Aktionszeitraum verlosen wir insgesamt:</p>
				<p className="mb-4 text-white">
					77x Ravensburger Spiel: „nimm2 Lachgummi memory“ (exklusive Sonderanfertigung für Storck) sowie nimm2 Lachgummi Frucht 250g, nimm2 Lachgummi Happies 325g und nimm2
					Lachgummi Heroes 225g
				</p>
				<h4 className="h4 text-base pb-0">Gewinne an Dienstagen</h4>
				<p className="mb-4 text-white">An allen Dienstagen im Aktionszeitraum verlosen wir insgesamt:</p>
				<p className="mb-4 text-white">44x nimm2 Lachgummi Liegestuhl sowie nimm2 Lachgummi Frucht 250g und nimm2 Lachgummi Milchgeister 225g</p>

				<h4 className="h4 text-base pb-0">Gewinne an Mittwochen</h4>
				<p className="mb-4 text-white">An allen Mittwochen im Aktionszeitraum verlosen wir insgesamt:</p>
				<p className="mb-4 text-white"> 77x nimm2 Sommer Hit Strandtasche und nimm2 soft Splash Beachball Set sowie nimm2 Sommer Hit 300g und nimm2 soft Splash 240g</p>
				<h4 className="h4 text-base pb-0">Gewinne an Donnerstagen</h4>
				<p className="mb-4 text-white">An allen Donnerstagen im Aktionszeitraum verlosen wir insgesamt:</p>
				<p className="mb-4 text-white">
					110x Produktpaket von nimm2, bestehend aus nimm2 Lachgummi Sofies Frucht 225g, nimm2 Lachgummi Frucht 250g, nimm2 Lachgummi Fluffies 200g, nimm2 Lachgummi Cola Flaschies
					200g, nimm2 Lachgummi Milchgeister 225g, nimm2 Lachgummi Mümmelbande 200g, nimm2 Lachgummi Apfellinge 225g, nimm2 Bonbon Orange Zitrone 240g, nimm2 soft 195g, nimm2 Lolly
					120g
				</p>
				<h4 className="h4 text-base pb-0">Gewinne an Freitagen:</h4>
				<p className="mb-4 text-white">An allen Freitagen im Aktionszeitraum verlosen wir insgesamt:</p>
				<p className="mb-4 text-white">
					44x Koffer der Marke American Tourister, Modell Soundbox 55cm in der Farbe Spicy Peach sowie nimm2 Bonbon Orange Zitrone 240g und nimm2 soft Frucht 195g
				</p>
				<h4 className="h4 text-base pb-0">Gewinne an Samstagen</h4>
				<p className="mb-4 text-white">An allen Samstagen im Aktionszeitraum verlosen wir insgesamt:</p>
				<p className="mb-4 text-white">
					44x nimm2 Lachgummi Cola Flaschies Luftmatratze und nimm2 Lachgummi Fluffies Badehandtuch sowie nimm2 Lachgummi Cola Flaschies 200g und nimm2 Lachgummi Fluffies 200g
				</p>
				<h4 className="h4 text-base pb-0">Gewinne an Sonntagen</h4>
				<p className="mb-4 text-white">An allen Sonntagen im Aktionszeitraum verlosen wir insgesamt: </p>
				<p className="mb-4 text-white">77x nimm2 Wikinger Wurfspiel sowie nimm2 Lachgummi Gartenzwerge 200g und nimm2 Lachgummi Mümmelbande 200g</p>
				<p className="mb-4 text-white">
					Für die Übersendung der Sachgewinne werden im Rahmen der Promotion personenbezogene Daten (Vorname, Name, Adresse sowie optional E-Mail-Adresse) abgefragt. Für die
					vollständige und korrekte Angabe ist der Teilnehmer verantwortlich. Der Versand der Sachgewinne erfolgt innerhalb von vier Wochen nach Teilnahme per DHL, ausschließlich
					innerhalb Deutschlands an die Storck über die entsprechende Eingabemaske mitgeteilte Adresse. Sollte der Teilnehmer keine Kontaktdaten für den Versand des Gewinns
					angegeben haben oder unter der angegebenen Adresse eine Zustellung nicht möglich sein, erlischt der Anspruch auf den Gewinn.
				</p>
				<p className="mb-4 text-white">Eine Barauszahlung der Gewinne ist nicht möglich.</p>
				<h3 className="h3 py-4">5. Datenschutz</h3>
				<p className="mb-4 text-white">
					Storck nutzt die im Rahmen der Promotion verarbeiteten personenbezogenen Daten ausschließlich zum Zwecke der Durchführung und Abwicklung der Promotion.
				</p>
				<p className="mb-4 text-white">
					Im Rahmen der Promotion arbeitet Storck mit seiner Tochtergesellschaft Storck Deutschland KG, Waldstraße 27, 13403 Berlin, sowie dem sorgfältig ausgewählten Dienstleister
					direct services Gütersloh GmbH, Reinhard-Mohn-Straße 300, 33333 Gütersloh zusammen. Die für Storck tätigen Auftragsverarbeiter sind ordnungsgemäß auf die Einhaltung des
					Datenschutzes verpflichtet. Eine weitergehende Nutzung durch Storck oder Dritte findet nicht statt.
				</p>
				<p className="mb-4 text-white">
					Weitere Informationen zum Datenschutz finden Sie unter:{" "}
					<a href="https://www.nimm2.de/de/datenschutz/" target="_blank" rel="noopener noreferrer" className="underline">
						https://www.nimm2.de/de/datenschutz/
					</a>
				</p>
				<h3 className="h3 py-4">6. Sonstige Bestimmungen </h3>
				<p className="mb-4 text-white">
					Storck behält sich vor, diese Teilnahmebedingungen ohne Vorankündigung zu ändern und das Gewinnspiel ohne Angaben von Gründen für einen vorübergehenden Zeitraum
					auszusetzen oder vorzeitig zu beenden, wenn die ordnungsgemäße Durchführung aus technischen, rechtli-chen oder sonstigen Gründen nicht mehr gewährleistet werden kann. Für
					Datenverluste, insbesondere im Wege der Datenübertragung, und andere technische Defekte übernehmen Storck und die beteiligten Unternehmen keine Haftung.
				</p>
				<p className="mb-4 text-white">Das Gewinnspiel unterliegt ausschließlich dem Recht der Bundesrepublik Deutschland.</p>
				<p className="mb-4 text-white">Der Rechtsweg ist ausgeschlossen.</p>
			</div>
			<Fruchtgummi />
		</div>
	)
}
export default Terms
