export const INCLUDE_AZ = false

export enum INPUT_TYPES {
	COMPONENT = "component",
	TEXT = "text",
	EMAIL = "email",
	SELECT = "select",
	DATE = "date",
	CHECKBOX = "checkbox",
}

const formRegEx = {
	// eslint-disable-next-lin
	email: "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$",
	numerical: "^[0-9]*$",
	// eslint-disable-next-line
	birthdate: "^([0-2][0-9]|(3)[0-1])(.)(((0)[0-9])|((1)[0-2]))(.)d{4}$",
	name: "^[a-zA-ZÀ-ÖØ-öø-ÿäöüÄÖÜáçíóôąćČčđėěğīİıĶŁłńŕśŞşŠšūźżŽž̈БКМСавгжиклнорстьіẞ]+$",
}

export const formDefinition: any = [
	{
		type: INPUT_TYPES.EMAIL,
		label: "E-Mail",
		name: "email",
		errorMessage: "Das Feld darf nicht leer sein oder das Format ist ungültig.",
		inputProps: {
			minLength: 1,
			pattern: formRegEx.email,
		},
	},
	{
		type: INPUT_TYPES.TEXT,
		label: "Vorname",
		name: "firstname",
		errorMessage: "Bitte Vornamen eingeben.",
		inputProps: {
			required: true,
			minLength: 1,
			pattern: formRegEx.name,
		},
	},
	{
		type: INPUT_TYPES.TEXT,
		label: "Nachname",
		name: "lastname",
		errorMessage: "Bitte Nachnamen eingeben.",
		inputProps: {
			minLength: 1,
			required: true,
			pattern: formRegEx.name,
		},
	},
	{
		type: INPUT_TYPES.TEXT,
		label: "Straße",
		name: "street",
		errorMessage: "Bitte Straße eingeben.",
		inputProps: {
			minLength: 1,
			required: true,
			autoComplete: "off",
			leave: "no",
		},
	},
	{
		type: INPUT_TYPES.TEXT,
		label: "Haus-Nr.",
		name: "housenr",
		errorMessage: "Bitte Haus-Nr. eingeben.",
		inputProps: {
			minLength: 1,
			maxLength: 10,
			required: true,
		},
	},
	{
		type: INPUT_TYPES.TEXT,
		label: "PLZ",
		name: "zip",
		errorMessage: "Das Feld muss 5 Zahlen enthalten.",
		inputProps: {
			maxLength: 5,
			minLength: 5,
			pattern: formRegEx.numerical,
			required: true,
			autoComplete: "off",
			leave: "no",
		},
	},

	{
		type: INPUT_TYPES.TEXT,
		label: "Stadt",
		name: "city",
		errorMessage: "Bitte Ort eingeben.",
		inputProps: {
			minLength: 1,
			required: true,
			autoComplete: "off",
			leave: "no",
		},
	},
]

export const GLOBAL_ERROR_MSG = `Bei der Übertragung Deiner Daten ist ein Fehler aufgetreten. Bitte versuche es erneut. Sollten die Probleme weiterhin bestehen, kannst Du dich an unser Service-Team wenden.  Du erreichst uns per E-Mail unter info@nimm2-gewinnspiel.de  oder telefonisch unter 030 / 47 06 00 60. Bitte entschuldige die Unannehmlichkeiten.`
