import { updateIframeScrollTo } from "../helper"

/**
 *
 */
export const scrollToErrorField = () => {
	setTimeout(() => {
		let errField = document.getElementsByClassName("js-formerror")
		if (errField.length > 0) {
			const firstErrorField: any = errField[0]

			updateIframeScrollTo(window.pageYOffset + firstErrorField.getBoundingClientRect().top - 200)
			window.scrollTo({
				top: window.pageYOffset + firstErrorField.getBoundingClientRect().top - 200,
				left: 0,
				behavior: "smooth",
				// top: firstErrorField.offsetTop, left: 0, behavior: 'smooth'
			})
		}
	}, 50)
}

/**
 * Check if all fields are valid (or: if ANY field is invalid).
 * @returns true || false
 */
export const allFieldsValid = (formDefinition, validation) => {
	let allFieldsValid = true
	let fieldsToSkip = ["googleAutocomplete"]

	for (let i = 0; i < Object.keys(formDefinition).length; i++) {
		if (fieldsToSkip.indexOf(formDefinition[i].name) < 0) {
			if (  validation["isInvalid_" + formDefinition[i].name]) {
				allFieldsValid = false
				console.log("validation failed for: " + formDefinition[i].name)
				break
			}
		}
	}

	// console.log({ allFieldsValid })
	return allFieldsValid
}

/**
 *
 * @param timeValue
 * @returns
 */
export const ageCheck = timeValue => {
	if (timeValue) {
		let date = new Date(timeValue.split(" ")[0])
		let now = new Date()

		const eighteenYears = (567648000 + 432000) * 1000 // 18 Jahre - 5 Tage // ??? schaltjahre oder so

		if (now.getTime() - date.getTime() > eighteenYears) {
			return false
		} else {
			return true
		}
	} else {
		return true
	}
}

export enum nameErrorStates {
	NAME_INVALID_CURSE_WORD = 1,
	NAME_INVALID_UNSUPPORTED_CHAR = 2,
	NAME_PENDING = 3,
}
