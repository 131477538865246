import { updateIframeHeight } from "../helper"

function Fruchtgummi() {
	return (
		<div className="relative ">
			<div className="bg-white absolute top-1/2 w-full left-0 h-1/2 z-0"></div>
			<img
				src={process.env.PUBLIC_URL + "/images/fruchtgummi.png"}
				className=" w-[50%] lg:w-full max-w-md relative mx-auto z-10"
				alt=""
				onLoad={() => updateIframeHeight("fruchtgummi")}
			/>
		</div>
	)
}

export default Fruchtgummi
